<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Saída</h1>

      <div class="box-table box-table-despesas">
        <v-skeleton-loader
            :loading="loadingTable"
            :transition="'fade-transition'"
            height="500"
            type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 

            <template v-slot:top>
              <BrToolBar @search="buscar" :configFilter="{ listaSelect: item, getItens: getItens, jsonData: false }" :addlFilter="true" />
            </template>

            <template v-slot:item.value="{ item }">
              {{ item.value | currency }}
            </template>

            <template v-slot:item.dtReference="{ item }">
              {{ item.dtReference | datas('DD/MM/YYYY') }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                Editar
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar } from '@/core/service/utils'

/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'despesas'

export default {
  name: 'Despesas',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data de referência', value: 'dtReference' },
      { align: 'start', class: 'table-header', text: 'Usuário', value: 'user.name' },
      { align: 'start', class: 'table-header', text: 'Tipo', value: 'type' },
      { align: 'start', class: 'table-header table-header-description', text: 'Descrição', value: 'description' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ]
  }),
  computed: {
    ...mapGetters(namespaceStore, ['item', 'listaItens', 'totalItens']),
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'clearItens']),

    init() {
      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao, order: { id: 'desc' } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },

    textButton (status) {
      switch(status) {
        case ('N'):
          return 'Cancelar'
        case ('A'):
          return 'Ativado'
      }
    },

    getColor(status) {
      switch(status) {
        case ('N'):
          return this.variables.colorPrimary
        case ('A'):
          return this.variables.colorSecondary
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &-despesas {
      .table {
        &-header {
          &-description {
            width: 480px !important;
          }
        }
      }
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>